@import "bootstrap/scss/mixins/breakpoints";
@import "../../../../variables";

.media-card-slider {
    max-width: calc(100% - 250px);
    margin: 0 auto;

    .slick-slider {
        margin-bottom: 0;
    }

    ul.media-card-slider-slides {
        padding-left: 0;
        list-style-type: none;

        .media-card-slide {
            list-style-type: none;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .image-card-header {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
    }

    .media-card-slide {
        h2,
        h3,
        p {
            margin-bottom: 12px;
        }

        h2,
        h3 {
            font-family: $brand-font;
            text-transform: uppercase;
        }

        .media-card-heading {
            font-size: $heading-4-font-size; // 25px

            &.font-size-five {
                font-size: $heading-5-font-size; // 20px
            }
        }

        .media-card-subheading {
            font-size: $heading-6-font-size; // 15px

            &.font-size-seven {
                font-size: $heading-7-font-size; // 12px
            }
        }

        .media-card-body {
            padding-top: 20px;
        }
    }

    .youtube-controls .icon-play {
        width: 60px;
        height: 60px;
    }

    @include media-breakpoint-down(md) {
        max-width: calc(100vw);
        margin-left: 25px;

        .slick-list {
            padding: 0 24% 0 0;
        }

        h2 {
            font-size: $heading-5-font-size;
        }

        // Hack it down to 140:69
        .embed-responsive-185by124::before {
            padding-top: 49.28571%;
        }

        button.slider-arrow {
            display: none;
        }

        .youtube-controls .icon-play {
            width: 50px;
            height: 50px;
        }
    }
}
